import ReactDOM from 'react-dom'
import React from "react";
import ProductList from './components/ProductList.jsx'
import ShoppingCart from './components/ShoppingCart.jsx'
import {applyMiddleware, createStore, compose, combineReducers} from "redux";
import {shoppingCartReducer} from "./components/shoppingcart/shoppingcart.reducer";
import {productDetailReducer} from "./components/productdetail/productdetail.reducer";
import {Provider} from "react-redux";
import ShoppingCartIndicator from "./components/ShoppingCartIndicator";
import ProductConfigurator from "./components/ProductConfigurator.jsx";
import {show} from "./components/shoppingcart/shoppingcart.actionCreators";
import QuickAddBar from "./components/QuickAddBar";
import {notificationsReducer} from "./components/notifications/notifications.reducer";
import NotificationList from "./components/notifications/NotificationList";

import SubscriptionForm from "./components/subscriptionform/SubscriptionForm";
import {subscriptionformReducer} from "./components/subscriptionform/subscriptionform.reducer";
import {quickaddbarReducer} from "./components/quickaddbar/quickaddbar.reducer";


import Swiper, {Navigation, Pagination} from 'swiper';
import mapstyles from "./map/mapstyles";
import {gastroPartners, merchants} from "./map/markerdata";

let initSwiper = () => {

// configure Swiper to use modules
    Swiper.use([Navigation, Pagination]);

// init Swiper:
    const swiper = new Swiper('.cocktail-list', {
        direction: 'horizontal',
        loop: true,
        height: 642,
        breakpoints: {
            700: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            }
        },
        // autoHeight: true,
        spaceBetween: 12,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
}

function callAPIMiddleware({dispatch, getState}) {
    return next => action => {
        const {types, callAPI, shouldCallAPI = () => true, payload = {}} = action

        if (!types) {
            // Normal action: pass it on
            return next(action)
        }

        if (
            !Array.isArray(types) ||
            types.length !== 3 ||
            !types.every(type => typeof type === 'string')
        ) {
            throw new Error('Expected an array of three string types.')
        }

        if (typeof callAPI !== 'function') {
            throw new Error('Expected callAPI to be a function.')
        }

        if (!shouldCallAPI(getState())) {
            return
        }

        const [requestType, successType, failureType] = types

        dispatch(
            Object.assign({}, payload, {
                type: requestType
            })
        )

        return callAPI().then(
            response =>
                dispatch(
                    Object.assign({}, payload, {
                        response,
                        type: successType
                    })
                ),
            error =>
                dispatch(
                    Object.assign({}, payload, {
                        error,
                        type: failureType
                    })
                )
        )
    }
}

// window.onscroll = function() {handleScroll()};
//
// var header = document.getElementById("mainnav");
// var sticky = header.offsetTop;
//
// function handleScroll() {
//     if (window.pageYOffset > sticky) {
//         header.classList.add("sticky");
//     } else {
//         header.classList.remove("sticky");
//     }
// }

const body = document.body;
// const nav = document.querySelector("#navwrapper");
// const menu = document.querySelector("#mainnav");
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
let lastScroll = 0;

window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
    } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
});

// let prevScroll = 0;
// document.querySelector("nav").style.position = "fixed";
// window.addEventListener("scroll", (e) => {
//     let currentScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
//     if (prevScroll < currentScroll) {
//         // we are scrolling downwards
//         // document.querySelector("nav").style.position = "relative";
//         // document.querySelector("body > .maincontent").style.paddingTop= "70px";
//         // document.querySelector("nav").style.top = "-68px";
//         // document.querySelector("nav").style.marginTop = "68px";
//         // document.querySelector("nav").style.height = "68px";
//
//         if (currentScroll < 70) {
//             document.querySelector("nav").style.top = "-70px";
//         } else {
//             document.querySelector("nav").style.top = "0";
//         }
//     } else {
//         // we are scrolling upwards
//         if (currentScroll > 70) {
//             document.querySelector("nav").style.top = "0";
//             // document.querySelector("body > .maincontent").style.paddingTop= "70px";
//         }else{
//
//         }
//         // document.querySelector("nav").style.top = "0";
//         // document.querySelector("nav").style.position = "fixed";
//         // document.querySelector("nav").style.marginTop = "0";
//     }
//     prevScroll = currentScroll;
// });

if (document.querySelector(".page-id-31")) {
    window.addEventListener("scroll", (e) => {
            let currentScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            let startEffectAt = screen.height * 1.2;
            let endEffectAt = screen.height * 2.0;
            let range = endEffectAt - startEffectAt;
            if (currentScroll > startEffectAt) {
                document.body.classList.add("light");
            }
            if (currentScroll < startEffectAt) {
                document.body.classList.remove("light");
            }
        }
    );
}


const logger = store => next => action => {
    console.group(action.type)
    console.info('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    console.groupEnd()
    return result
}
/**
 * Sends crash reports as state is updated and listeners are notified.
 */
const crashReporter = store => next => action => {
    try {
        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)
        // Raven.captureException(err, {
        //     extra: {
        //         action,
        //         state: store.getState()
        //     }
        // })
        throw err
    }
}
const thunk = store => next => action =>
    typeof action === 'function'
        ? action(store.dispatch, store.getState)
        : next(action)

const middlewareEnhancer = applyMiddleware(thunk, logger, callAPIMiddleware, crashReporter);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancers = composeEnhancers(middlewareEnhancer);

const store = createStore(combineReducers({
    shoppingCart: shoppingCartReducer,
    productDetail: productDetailReducer,
    notifications: notificationsReducer,
    subscriptionform: subscriptionformReducer,
    quickaddbar: quickaddbarReducer,
}), {}, composedEnhancers);

const insertReactComponent = (node, component, props) => {
    const children = [
        React.createElement(component, props),
    ].map((c, index) => {
        return {...c, key: index}
    });
    const provider = React.createElement(Provider, {store}, children);
    ReactDOM.render(provider, node);
}

const insertReactComponentsToSelector = (selector, component, props) => {
    [...document.querySelectorAll(selector)]
        .map((pl) => insertReactComponent(pl, component, props));
};

// insertReactComponentsToSelector("#tschuus-merchantsmap", MerchantsMap);

// return;
insertReactComponentsToSelector(".tschuus-subscriptionform", SubscriptionForm);
insertReactComponentsToSelector(".tschuus-shoppingcart", ShoppingCart);
insertReactComponentsToSelector(".tschuus-productlist", ProductList);
insertReactComponentsToSelector("#mainnav .shoppingcart, #mainnav .tschuus-shoppingcart-indicator-mobile", ShoppingCartIndicator);
insertReactComponentsToSelector(".tschuus-notifications", NotificationList);


[...document.querySelectorAll(".variations_form")]
    .map((pl) => {
            let props = {
                config: JSON.parse(pl.getAttribute('data-product_variations')),
                name: pl.getAttribute('data-product-title'),
                is_purchasable: pl.getAttribute('data-product-purchasable') === "true",
                purchase_notice: pl.getAttribute('data-product-purchase-notice'),
            };
            console.log(props);
            insertReactComponent(pl, ProductConfigurator, props);
        }
    );

let quickAddBar = document.querySelector(".tschuus-quick-add-bar");
if (quickAddBar) {
    insertReactComponent(quickAddBar, QuickAddBar, {});
}

const toggleClass = (node, className) => {

    if (node.classList.contains(className)) {
        node.classList.remove(className);
    } else {
        node.classList.add(className);
    }
}

[...document.querySelectorAll("#hamburger-icon")].map(link => {
    link.addEventListener('click', () => {
        toggleClass(document.querySelector("#mainnav"), "mobile-open");
        let navNode = document.querySelector("#mainnav");
        if (navNode.classList.contains("mobile-open")) {
            navNode.querySelector("#mainnav").classList.remove("mobile-open");
            document.querySelector("body").classList.remove("prevent-scroll");
        } else {
            navNode.querySelector("#mainnav").classList.add("mobile-open");
            document.querySelector("body").classList.add("prevent-scroll");
        }
    });
});

[...document.querySelectorAll(".tschuus-shoppingcart-opener")].map(el => {
    // console.log('opening shoppingcart');
    el.addEventListener('click', (e) => {
        store.dispatch(show());
    });
});

[...document.querySelectorAll("#mainnav a")].map(link => {
    link.addEventListener('click', e => {
        if (link.classList.contains("onpage-action")) {
            return;
        }
        if (link.dynClicked) {
            return;
        }
        e.preventDefault();
        console.dir('clicked');
        document.querySelector("#mainnav").classList.remove("mobile-open");
        document.querySelector("body").classList.remove("prevent-scroll");
        document.querySelector("body").classList.add("fading");
        setTimeout(() => {
            document.querySelector("body").classList.remove("fading");
            link.click();
            link.dynClicked = true;
        }, 500);
    });
});
(function () {
    initSwiper();

})();

let map;

let gastroMarkers = [];
let merchantMarkers = [];


const renderMarker = (map, markerData) => {
    const infowindow = new google.maps.InfoWindow({
        content: "<div>" + markerData.name + "<br />" + markerData.address,
    });

    const marker = new google.maps.Marker({
        position: {
            lat: markerData.latLng[0],
            lng: markerData.latLng[1],
        },
        map,
        title: markerData.name,
    });
    marker.addListener("click", () => {
        infowindow.open(map, marker);
    });
    return marker;
}

function initMap() {
    if (!document.getElementById("tschuus-merchantsmap")) return;
    const styledMapType = new google.maps.StyledMapType(mapstyles);

    map = new google.maps.Map(document.getElementById("tschuus-merchantsmap"), {
        center: {lat: 48.17, lng: 16.644},
        // center: {lat: -48.17, lng: 16.006},
        zoom: 9,
        disableDefaultUI: true,
    });

    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");

    merchantMarkers = merchants.map(gp => renderMarker(map, gp));
    gastroMarkers = gastroPartners.map(gp => renderMarker(map, gp));

    gastroMarkers.map(gm => gm.setMap(null));
}

let mapLinks = [...document.querySelectorAll("#findtschuus ul li a")];
if (mapLinks.length === 2) {
    // console.dir(mapLinks);
    mapLinks[0].addEventListener("click", () => {
        merchantMarkers.map(gm => gm.setMap(map));
        gastroMarkers.map(gm => gm.setMap(null));
    });
    mapLinks[1].addEventListener("click", () => {
        merchantMarkers.map(gm => gm.setMap(null));
        gastroMarkers.map(gm => gm.setMap(map));
    });
}
window.initMap = initMap;

// setInterval(() => { toggleClass(document.querySelector("#mainnav"), "mobile-open")}, 2000);