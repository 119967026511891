import React from 'react';
import {connect} from "react-redux";
import {show} from "./shoppingcart/shoppingcart.actionCreators";
import icon from '../assets/icons/shoppingcart.svg';

class ShoppingCartIndicator extends React.Component {
    render() {
        return <a onClick={() => {
            this.props.dispatch(show());
        }}>
            <img src={icon}/>
            <span className={"indicator"}>{this.props.numItems}</span>
        </a>
    }
}

const mapStateToProps = (state) => {
    return {
        numItems: state.shoppingCart.products.map(p => p.amount).reduce((a, c) => a + c, 0)
    };
};
export default connect(mapStateToProps)(ShoppingCartIndicator);
