import bottleImg from "./../../assets/flasche-einzeln.png";
import update from 'immutability-helper';
import {products} from './../../Api.js';

export function shoppingCartReducer(state = {
    visible: false,
    loading: false,
    products: [
    ]
}, action) {
    switch (action.type) {
        case "SHOW":
            return {
                ...state,
                visible: true
            };
        case "HIDE":
            return {
                ...state,
                visible: false
            };
        case "LOAD_ITEMS_SUCCESS":
            return update(state, {
                products: {$set: action.payload.products}
            });
        // case "ADD_ITEM_SUCCESS":
        //     let existingIndex = state.products.findIndex(pr => pr.bottleSizeId == action.bottleSizeId && pr.packageSizeId == action.packageSizeId && pr.productId == action.productId);
        //     if (-1 !== existingIndex) {
        //         return update(state, {
        //             products: {
        //                 [existingIndex]: {
        //                     amount: {$apply: old => old + action.amount}
        //                 }
        //             }
        //         });
        //     } else {
        //         const product = products.find(pr => pr.id == action.productId);
        //         const bottleSize = product.variations.find(v => v.value == action.bottleSizeId);
        //         const packageSize = bottleSize.variations.find(v => v.value === action.packageSizeId);
        //         const newItem = {
        //             title: product.name,
        //             subtitle: bottleSize.name,
        //             productId: action.productId,
        //             bottleSizeId: action.bottleSizeId,
        //             packageSizeId: action.packageSizeId,
        //             price: packageSize.price,
        //             image: product.image,
        //             amount: action.amount
        //         };
        //         return update(state, {
        //             products: {
        //                 $push: [newItem]
        //             }
        //         });
        //     }
        // case "SET_PACKAGE_SIZE_SUCCESS":
        //     // console.dir(action);
        //     let currentProduct = state.products[action.index];
        //     let currentProductId = state.products[action.index].productId;
        //
        //     return {
        //         ...state,
        //         products: update(state.products, {
        //             [action.index]: {
        //                 packageSizeId: {
        //                     $set: action.packageSizeId
        //                 },
        //                 price: {
        //                     $set: products.find(pr => pr.id == currentProductId).variations.find(v => v.value == currentProduct.bottleSizeId).variations.find(v => v.value == action.packageSizeId).price,
        //                 }
        //             }
        //         }),
        //     };
        case "REMOVE_ITEM_SUCCESS":
            let index = state.products.findIndex(p => p.removeLink === action.payload.removeLink);
            if(index === -1){
                return state;
            }
            return {
                ...state,
                products: update(state.products, {
                    $splice: [[index, 1]]
                }),
            }
        // case "INCREASE_AMOUNT_SUCCESS":
        //     return {
        //         ...state,
        //         products: update(state.products, {
        //             [action.index]: {
        //                 amount: {
        //                     $apply: (old) => {
        //                         return old + 1;
        //                     }
        //                 }
        //             }
        //         }),
        //     }
        // case "DECREASE_AMOUNT_SUCCESS":
        //     return {
        //         ...state,
        //         products: update(state.products, {
        //             [action.index]: {
        //                 amount: {
        //                     $apply: (old) => {
        //                         return Math.max(1, old - 1);
        //                     }
        //                 }
        //             }
        //         }),
        //     }
        default:
            return state
    }
}
