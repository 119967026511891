import update from 'immutability-helper';


export function subscriptionformReducer(state = {
    subscriptionItems: []
}, action) {
    switch (action.type) {
        case "INCREASE_PRODUCT_TO_SUBSCRIPTIONFORM":
            let exists = state.subscriptionItems.findIndex(i => i.packageSize === action.payload.packageSize && i.bottleSize === action.payload.bottleSize && i.grape === action.payload.grape);
            if (exists < 0) {
                return update(state, {
                    subscriptionItems: {
                        $push: [{...action.payload, amount: 1}]
                    }
                });
            } else {
                return update(state, {
                    subscriptionItems: {
                        [exists]: {
                            amount: {
                                $apply: (old => {
                                    return old + 1;
                                })
                            }
                        }
                    }
                });
            }
        case "REMOVE_PRODUCT_FROM_SUBSCRIPTIONFORM":
            let existingToRemove = state.subscriptionItems.findIndex(i => i.packageSize === action.payload.packageSize && i.bottleSize === action.payload.bottleSize && i.grape === action.payload.grape);
            return update(state, {
                subscriptionItems: {
                    $splice: [[existingToRemove, 1]]
                }

            });
        case "DECREASE_PRODUCT_FROM_SUBSCRIPTIONFORM":
            let existing = state.subscriptionItems.findIndex(i => i.packageSize === action.payload.packageSize && i.bottleSize === action.payload.bottleSize && i.grape === action.payload.grape);
            let existingItem = state.subscriptionItems[existing];
            if (existingItem.amount > 1) {
                return update(state, {
                    subscriptionItems: {
                        [existing]: {
                            amount: {
                                $apply: (old => {
                                    return old - 1;
                                })
                            }
                        }
                    }
                });
            } else {
                return update(state, {
                    subscriptionItems: {
                        $splice: [[existing, 1]]
                    }
                });
            }
        default:
            return state;
    }
}