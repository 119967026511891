export function productDetailReducer(state = {
    product: {},
    "bottleSize": "not_initialized",
    "packageSize": "_not_initialized",
    "totalPrice": "not_initialized",
    "packageSizeIndex": 0,
    "bottleSizeIndex": 0,
}, action) {
    switch (action.type) {
        case "DETAILPAGE_SET_PRODUCT":
            const bottleVariation_1 =  action.payload.product.variations[0];
            const packageVariation_1 = action.payload.product.variations[0].variations[0];
            return {
                ...state,
                product: action.payload.product,
                bottleSize: bottleVariation_1.name,
                packageSize: packageVariation_1.name,
                totalPrice: packageVariation_1.price,
            }
        case "DETAILPAGE_SET_PACKAGESIZE_INDEX":
            const packageVariation = state.product.variations[state.bottleSizeIndex].variations[action.payload.packageSizeIndex];
            return {
                ...state,
                packageSize: packageVariation.name,
                totalPrice: packageVariation.price,
            };
        case "DETAILPAGE_SET_BOTTLESIZE_INDEX":
            const bottleVariation = state.product.variations[action.payload.bottleSizeIndex];
            const packageVariationFoo = state.product.variations[action.payload.bottleSizeIndex].variations[0];
            return {
                ...state,
                bottleSize: bottleVariation.name,
                totalPrice: packageVariationFoo.price,
            };
        default:
            return state
    }
}
