export const gastroPartners = [
    {
        name: "Hoffmann´s Weinzei",
        address: "Sandhöhe 9, 7123 Mönchhof",
        latLng: [47.8850941,16.9445084],
    },
    {
        name: "Brandstetter",
        address: "Hernalser Hauptstraße 134, 1170 Wien",
        latLng: [48.2214817,16.3218046],
    },
    {
        name: "Humers Uferhaus",
        address: "Uferstraße 20, 2304 Orth an der Donau",
        latLng: [48.1244648, 16.7034478],
    },
    {
        name: "Gasthaus Frank",
        address: "Stiftsgasse 2, 7123 Mönchhof",
        latLng: [47.8828917, 16.9351611],
    },
    {
        name: "Café Friends",
        address: "Erzherzog-Rainer-Ring 2, 2500 Baden",
        latLng: [48.0067716, 16.2305838],
    },
    {
        name: "Café NIL",
        address: "Siebensterngasse 39, 1070 Wien",
        latLng: [48.2020209, 16.3476625],
    },
    {
        name: "Spazio Cafe&Bistro",
        address: "Neubaugasse 48-50, 1070 Wien",
        latLng: [48.2023118, 16.346999],
    },
    {
        name: "The Pelican Coffee Company",
        address: "Pelikangasse 4, 1090 Wien",
        latLng: [48.2157794, 16.3461446],
    },
    {
        name: "Motto - Restaurant.Bar",
        address: "Rüdigergasse 1, 1050 Wien",
        latLng: [48.1931187, 16.3553534],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "Wienerstraße 66, 7100 Neusiedl am See",
        latLng: [47.9592451, 16.8341347],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "Hauptplatz 5, 7100 Neusiedl am See",
        latLng: [47.9882221, 16.8947992],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "Untere Hauptstraße 121, 7122 Gols",
        latLng: [47.8941102, 16.9164851],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "Landstraße 86-88, 2410 Hainburg an der Donau",
        latLng: [48.1415805,16.9254046],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "EKZ Galleria Danubia, Pressburger Reichsstraße 1, 2410 Hainburg an der Donau",
        latLng: [48.1487894, 16.9461868],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "Hauptstraße 12, 7132 Frauenkirchen",
        latLng: [47.8367635,16.9260984],
    },
    {
        name: "Bäckerei & Konditorei Naglreiter GmbH",
        address: "Neusiedler Straße 40, 7000 Eisenstadt",
        latLng: [47.8469442,16.5313981],
    },
    {
        name: "Dirt Water Café & Bar",
        address: "Kandlgasse 35, 1070 Wien",
        latLng: [48.2025246,16.34018],
    },
    {
        name: "Eventlocation Franz von Grün",
        address: "In der Kellergasse 71, 2464 Arbesthal",
        latLng: [48.0704114,16.6898468],
    },
    {
        name: "Restaurant Mraz & Sohn",
        address: "Wallensteinstraße 59, 1200 Wien",
        latLng: [48.2313526,16.3738554],
    },
    {
        name: "Haslauerhof",
        address: "Hauptstraße 17, 2402 Maria Ellend",
        latLng: [48.1180051,16.7141462],
    },
    {
        name: "Gastwirtschaft Floh",
        address: "Tullner Straße 1, 3425 Tulln an der Donau",
        latLng: [48.3370421,16.1104023],
    },
    {
        name: "Landgasthaus Schiller ",
        address: "Hauptstraße 31, 2453 Sommerein",
        latLng: [47.9884573,16.6589981],
    },
    {
        name: "Hotel-Restaurant Mühltalhof ",
        address: "Unternberg 6, 4120 Unternberg",
        latLng: [48.4813556,14.0024139],
    },
    {
        name: "Restaurant Obauer",
        address: "Markt 46, 5450 Werfen",
        latLng: [47.475372,13.1901365],
    },
    {
        name: "NEU NEUSIEDLER",
        address: "Untere Hauptstraße 6, 7100 Neusiedl am See",
        latLng: [47.947992,16.8418009],
    },
    {
        name: "ROSSO.19",
        address: "Sandhöhe 19, 7123 Mönchhof",
        latLng: [47.8851619,16.9306395],
    },

];

export const merchants = [
    {
        name: "LGV-Gärtnergschäftl",
        address: "Kettenbrückengasse 20, 1040 Wien",
        latLng: [48.196092, 16.3578785],
    },
    {
        "name": "EDELGREISSLEREI OPOCENSKY",
        address: "Favoritenstraße 25, 1040 Wien",
        latLng: [48.1898639, 16.3701081],
    },
    {
        name: "Pöhl am Naschmarkt",
        address: "Naschmarkt 167, 1060 Wien",
        latLng: [48.1985279, 16.3615616],
    },
    {
        name: "St. Martins Therme & Lodge",
        address: "Im Seewinkel 1, 7132 Frauenkirchen",
        latLng: [47.8078761, 16.9156621],
    },
    {
        name: "LEEB - alles apfel",
        address: "Wienerstraße 40, 7161 St. Andrä am Zicksee",
        latLng: [47.7901389, 16.9391079],
    },
    {
        name: "Gerhard Markowitsch GmbH",
        address: "Pfarrgasse 6, 2464 Göttlesbrunn",
        latLng: [48.0553373, 16.7361492],
    },
    {
        name: "Naturbackstube Elisabeth Unger",
        address: "Pamhagener Straße 35, 7151 Wallern",
        latLng: [47.7211161,16.9314678]
    },
    {
        name: "Hans Reh GmbH",
        address: "Burggasse 20, 1070 Wien",
        latLng: [48.2042578,16.353466]
    },
    {
        name: "Johannas feiner Laden",
        address: "Cobenzlgasse 6, 1190 Wien",
        latLng: [48.2559328,16.3421202]
    },
    {
        name: "Netzwerkschmiede Schlossahaus",
        address: "Stiftsgasse 18, 7123 Mönchhof",
        latLng: [47.8819933,16.934051]
    },
    {
        name: "KellerKünstler Weinhandel KG",
        address: "In der Kellergasse 36, 2464 Arbesthal",
        latLng: [48.0677551,16.6951651]
    },
    {
        name: "Hittinger´s Genuss-Greisslerei",
        address: "Neustiftgasse 64, 1070 Wien",
        latLng: [48.2056892,16.3445586]
    },
    {
        name: "Auszeit by Kirschner",
        address: "Wiener Straße 17, 7123 Mönchhof",
        latLng: [47.8856034,16.9367821]
    },
    {
        name: "Warenhandlung Wenighofer",
        address: "Marxergasse 13, 1030 Wien",
        latLng: [48.2070054,16.3876862]
    },
];
