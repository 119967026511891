import {connect} from "react-redux";
import React from "react";

export default connect(state => {
    return {
        notifications: state.notifications.notifications
    }
})(props => {
    return <div className={"Notifications"}>
        <ul>
            {props.notifications.map((n, index) => {
                return <li key={index}>
                    <span className={"text"}>
                     {n.text}
                    </span>
                    <span className={"close icon-close-red"} onClick={e => {
                        e.preventDefault();
                        props.dispatch({
                            type: "CLEAR_NOTIFICATION",
                            payload: {
                                id: n.id
                            }
                        });
                    }}/>
                </li>
            })}
        </ul>
    </div>
});
