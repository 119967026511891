export function quickaddbarReducer(state = {
    product: {},
}, action) {
    switch (action.type) {
        case "QUICKADDBAR_SET_PRODUCT":
            return {
                ...state,
                product: action.payload.product,
            }
        default:
            return state
    }
}
