export const increaseProductToSubscriptionForm = (grape, packageSize, bottleSize) => {
    return {
        type: "INCREASE_PRODUCT_TO_SUBSCRIPTIONFORM",
        payload: {
            grape,
            packageSize,
            bottleSize
        }
    };
}

export const reduceProductFromSubscriptionForm = (grape, packageSize, bottleSize) => {
    return {
        type: "DECREASE_PRODUCT_FROM_SUBSCRIPTIONFORM",
        payload: {
            grape,
            packageSize,
            bottleSize
        }
    };
}

export const removeProductFromSubscriptionForm = (grape, packageSize, bottleSize) => {
    return {
        type: "REMOVE_PRODUCT_FROM_SUBSCRIPTIONFORM",
        payload: {
            grape,
            packageSize,
            bottleSize
        }
    };
}
