import {connect} from "react-redux";
import React from "react";
import {addItem} from "./shoppingcart/shoppingcart.actionCreators";

class QuickAddBar extends React.Component {
    render(){
        return <div>

            <div className="desktop-infobar">
                <div className="product-name">{this.props.product.name} <span>{this.props.product.bottleSize} | {this.props.product.packageSize}</span></div>
                <button className="add-to-shoppingcart" onClick={(e) =>{
                    e.preventDefault();
                    this.props.dispatch(addItem(this.props.product.variationId, 1));
                }}>
                    {/*<span className="price">{this.props.product.price} &euro;</span>*/}
                    <span className="text">In den Warenkorb</span>
                </button>
            </div>

        </div>
    }
}

export default connect((state) => {
    return {
        product: state.quickaddbar.product
    };
})(QuickAddBar);
