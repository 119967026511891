import React from 'react';
// import Api from "../Api";
import Api from "../RealApi.js";
import {connect} from "react-redux";
import {
    hide,
    setAmountForCartItem, loadShoppingCart,
    removeItem,
} from "./shoppingcart/shoppingcart.actionCreators";
import {formatEuroMoney} from "../moneyformat";

class ShoppingCart extends React.Component {
    componentDidMount() {

        this.props.dispatch(loadShoppingCart());
    }

    render() {
        if (this.props.shoppingCartState.loading) return <div>Lade</div>;

        const totalPrice = this.props.shoppingCartState.products.map(p => p.amount * p.price).reduce((cur, carry) => cur + carry, 0);

        return <div id={"shoppingbasket-wrapper"}>
            <div className={"continue-shopping " + (this.props.shoppingCartState.visible ? "" : "disabled")}
                 onClick={() => {
                     this.props.dispatch(hide());
                 }}>weiter shoppen
            </div>
            <div id={"shoppingbasket-overlay"} className={this.props.shoppingCartState.visible ? "" : "disabled"}
                 onClick={() => {
                     this.props.dispatch(hide());
                 }}>
            </div>
            <section id="shoppingbasket" className={this.props.shoppingCartState.visible ? "" : "disabled"}>
                <div className="header"><span className="close" onClick={() => {
                    this.props.dispatch(hide());
                }}></span>
                    <h4>Warenkorb</h4>
                </div>
                <div className="articles">
                    {this.props.shoppingCartState.products.map((p, index) => {
                        return <div className="article" key={index}>
                            <div className="article-info">
                                <div className="image"><img src={p.image}/></div>
                                <div className="content">
                                    <div className="title">{p.title}</div>
                                    <div className="description">{p.packageSize} | {p.bottleSize}</div>
                                    <div className="amount">
                                        <div className="minus" onClick={(e) => {
                                            this.props.dispatch(setAmountForCartItem(p.cartHash, p.amount - 1));
                                        }}/>
                                        <div className="amount">{p.amount} Karton{p.amount > 1 && "s"}</div>
                                        <div className="plus" onClick={(e) => {
                                            this.props.dispatch(setAmountForCartItem(p.cartHash, p.amount + 1));
                                        }}/>
                                    </div>
                                    <div className="totalPrice">{formatEuroMoney(p.amount * p.price)} €</div>
                                </div>
                            </div>
                            <div style={{display: "none"}}>{p.removeLink}</div>
                            <div className="remove" onClick={(e) => {
                                this.props.dispatch(removeItem(p.removeLink));
                            }}>Entfernen
                            </div>
                        </div>
                    })}
                </div>
                <div className="summary">
                    <div className="totalPrice">
                        <div className="label">Gesamt:</div>
                        <div className="value">{formatEuroMoney(totalPrice)} €</div>
                    </div>
                    <div className="notice">Die Versandkosten werden im Checkout berechnet</div>
                    <div className={"area-paymentButton"}><a className="paymentButton" href={"/checkout/"}><span
                        className="price">{formatEuroMoney(totalPrice)} €</span><span
                        className="label">Zum Checkout</span></a>
                    </div>
                </div>
            </section>
        </div>
    }
}

const mapStateToProps = state => {
    return {shoppingCartState: state.shoppingCart};
};
export default connect(mapStateToProps)(ShoppingCart);