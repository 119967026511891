import update from 'immutability-helper';

const updateRemoveById = (arr, id) =>{
    const index = arr.findIndex(a => a.id === id);
    if(-1 !== index){
        let newD = update(arr, {
            $splice: [[index, 1]]
        });
        console.dir(newD);
        return newD;
    }
    console.dir('not found');
    return arr;
}

export function notificationsReducer(state = {
    notifications: []
}, action) {
    switch (action.type) {
        case "ADD_NOTIFICATION":
            return update(state, {
                notifications: {$push: [action.payload]}
            });
        case "CLEAR_NOTIFICATION":
            return update(state, {
                notifications: {$set: updateRemoveById(state.notifications, action.payload.id)}
            });
        default:
            return state;
    }
}
