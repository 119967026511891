import React, {useState} from 'react';
import {formatEuroMoney} from "../../moneyformat";
import {connect} from "react-redux";
import {
    increaseProductToSubscriptionForm,
    reduceProductFromSubscriptionForm,
    removeProductFromSubscriptionForm
} from "./subscriptionform.actionCreators";
import {loadShoppingCart} from "../shoppingcart/shoppingcart.actionCreators";

const getLabel = (grape) => {
    switch (grape) {
        case "veltliner":
            return "Grüner Veltliner";
        case "zweigelt":
            return "Zweigelt";
        default:
            return "UNDEFINIERTE TRAUBENSORTE";
    }
}

const getPrice = (bottleSize, packageSize) => {
    switch (parseInt(bottleSize)) {
        case 250:
            return packageSize * 330;
        case 750:
            return packageSize * 490;
        default:
            return "UNDEFINIERTER PREIS"
    }
}

const SubscriptionForm = (props) => {
    const [grape, setGrape] = useState("veltliner");
    const [packageSize, setPackageSize] = useState(6);
    const [bottleSize, setBottleSize] = useState(250);
    return <form>
        <h2>Abo Anfrage</h2>

        <p className={"info-050"}>Rebsorte und Menge pro Monat</p>
        <br/>
        <div className={"grid lg:grid-cols-12 gap-2"}>

            <select className={"lg:col-span-6"} value={grape} onChange={(e) => {
                setGrape(e.target.value);
            }}>
                <option value={"zweigelt"}>Zweigelt</option>
                <option value={"veltliner"}>Grüner Veltliner</option>
            </select>
            <select className={"lg:col-span-3"} value={packageSize} onChange={(e) => {
                setPackageSize(e.target.value);
            }}>
                <option value={6}>6 Fl.</option>
                <option value={12}>12 Fl.</option>
                <option value={18}>18 Fl.</option>
            </select>
            <select className={"lg:col-span-3"} value={bottleSize} onChange={(e) => {
                setBottleSize(e.target.value);
            }}>
                <option value={250}>0.25 L</option>
                <option value={750}>0.75 L</option>
            </select>
            <button className={"button-submit lg:col-span-12"} style={{width: "100%"}} onClick={(e) => {
                e.preventDefault();
                props.dispatch(increaseProductToSubscriptionForm(grape, packageSize, bottleSize));
            }}>Produkt hinzufügen
            </button>
        </div>
        <hr className={"separator-dark col-span-12"}/>
        {props.subscriptionItems.length > 0 && <p>Ausgewählte Produkte</p>}
        {props.subscriptionItems.length > 0 && <div className={"subscriptions-productlist"}>
            <hr/>
            {props.subscriptionItems.map(si => {
                return <div className={"product"} key={si.packageSize + "_" + si.bottleSize + "_" + si.grape}>
                    <div className={"left"}>
                        <h4>{getLabel(si.grape)}
                            <span>{(si.bottleSize / 1000).toString().replace(".", ",")} L | {si.packageSize} Fl.</span>
                        </h4>
                        <div className="amount">
                            <div className="minus" onClick={(e) => {
                                props.dispatch(reduceProductFromSubscriptionForm(si.grape, si.packageSize, si.bottleSize));
                            }}></div>
                            <div className="amount-packages">
                                {si.amount} Kartons
                            </div>
                            <div className="plus" onClick={(e) => {
                                props.dispatch(increaseProductToSubscriptionForm(si.grape, si.packageSize, si.bottleSize));
                            }}></div>
                        </div>
                    </div>

                    <div className={"right"}>
                        <div className="remove icon-close-red" onClick={(e) => {
                            props.dispatch(removeProductFromSubscriptionForm(si.grape, si.packageSize, si.bottleSize));
                        }}>
                        </div>
                        <div
                            className={"price"}>{formatEuroMoney(getPrice(si.bottleSize, si.packageSize) * si.amount / 100)} €
                        </div>
                    </div>
                </div>
            })}
            <hr/>
        </div>}

        <div className={"lg:grid grid-cols-4 gap-3"}>
            <div>
                <label>Dein Vorname</label>
                <input type="text" placeholder={"Michael"}/>
            </div>
            <div>
                <label>Dein Nachname</label>
                <input type="text" placeholder={"Mustermann"}/>
            </div>
            <div>
                <label>Deine E-Mail-Adresse</label>
                <input type="email" placeholder={"meine@email.com"}/>
            </div>
            <div>
                <label>Deine Telefonnummer</label>
                <input type="tel" placeholder={"+43 ..."}/>
            </div>
        </div>
        <div className={"grid grid-cols-12 gap-3 mt-4"}>
            <div className={"col-span-12 lg:col-span-5 flex flex-row items-center"}>
                <input className={"mr-2"} type="checkbox"/>
                <label className={"m-0 self-center"}>Hiermit bestätige ich die <a
                    href={"/datenschutz"}>Datenschutzerklärung</a>.</label>
            </div>
            <a className="button-submit w-full text-center col-span-12 lg:col-span-3 lg:col-end-13"
               href="https://tschuus.at/checkout/order-received/"
               onClick={() =>{
                   let dispatch = props.dispatch;
                   dispatch({
                       type: 'SET_AMOUNT_REQUEST'
                   });
                   // types: ['INCREASE_AMOUNT_REQUEST', 'INCREASE_AMOUNT_SUCCESS', 'INCREASE_AMOUNT_FAILURE'],
                   fetch(wc_add_to_cart_params.ajax_url + "?action=qty_cart", {
                       // fetch('/?wc-ajax=add_to_cart', {
                       headers: {
                           "Content-Type": "application/x-www-form-urlencoded",
                       },
                       method: "POST",
                       body: "hash=" + cart_item_key + "&quantity=" + qty,
                   }).then(r => {
                       dispatch({
                           type: 'SET_AMOUNT_REQUEST_SUCCESS'
                       });
                       dispatch(loadShoppingCart());
                   }).catch(e => {
                       dispatch({
                           type: 'SET_AMOUNT_REQUEST_FAILURE',
                           error: e
                       });
                   });
               }}
            >Aboanfrage Absenden</a>
        </div>
    </form>
}

export default connect(state => {
    return {
        subscriptionItems: state.subscriptionform.subscriptionItems
    };
})(SubscriptionForm);