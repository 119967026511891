import bottleImg from "./assets/flasche-einzeln.png";
import overlayZweigelt from "./assets/trauben-overlay-zweigelt.svg";

const variations = [
    {
        name: "250 ml",
        value: "250ml",
        variations: [
            {
                name: "6 Fl",
                value: "250ml6bottles",
                price: 25,
            },
            {
                name: "12 Fl",
                value: "250ml12bottles",
                price: 50,
            },
            {
                name: "24 Fl",
                value: "250ml24bottles",
                price: 100,
            }
        ]
    },
    {
        name: "750 ml",
        value: "750ml",
        variations: [
            {
                name: "6 Fl",
                value: "750ml6bottles",
                price: 75,
            },
            {
                name: "12 Fl",
                value: "750ml12bottles",
                price: 150,
            },
            {
                name: "24 Fl",
                value: "750ml24bottles",
                price: 300,
            }
        ]
    },
];

const products = [
    {
        name: "Zweigelt",
        id: "zweigelt",
        shortDescription: "roter Traubensaft, vegan",
        detailPage: "produkt-detail-zweigelt.html",
        image: bottleImg,
        overlayImage: overlayZweigelt,
        brandColor: "#9B2028",
        description: "Ein sortenreiner roter Traubensaft, bei dem man gern tief ins Glas schaut. Leuchtend rot, erfrischend laut und ein bisschen frech auf alle Fälle. 100% Zweigelt Trauben, von Hand verlesen, vegan und natürlich ohne künstliche Zusätze",
        variations: variations
    },
    {
        id: "veltliner",
        name: "Grüner Veltliner",
        shortDescription: "weißer Traubensaft, vegan",
        detailPage: "produkt-detail-veltliner.html",
        image: bottleImg,
        overlayImage: overlayZweigelt,
        brandColor: "#91AE54",
        description: "Ein sortenreiner weißer Traubensaft, bei dem man gern tief ins Glas schaut. Leuchtend rot, erfrischend laut und ein bisschen frech auf alle Fälle. 100% Zweigelt Trauben, von Hand verlesen, vegan und natürlich ohne künstliche Zusätze",
        variations: variations
    },
    {
        id: "mixbox",
        name: "Gemischte Box",
        detailPage: "produkt-detail-mixbox.html",
        shortDescription: "Zweigelt und gr. Veltliner, vegan",
        overlayImage: overlayZweigelt,
        image: bottleImg,
        description: "",
        variations: variations
    }
];


export {products};

export default {
    increaseAmount: (itemIndex) => {
        // shoppingCartState = update(shoppingCartState, )
    },
    addToShoppingCart: (id, bottleSizeId, packageSizeId, amount) => {
        console.log('adding to shopping cart');
        return new Promise((s, e) => {
            s({
                success: true
            });
        });
    },
    GetProducts: () => {
        return new Promise((s, e) => {
            s(products);
        });
    }
};
