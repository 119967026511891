import React from 'react';
import Api from './../Api.js'
import {connect} from "react-redux";
import ProductConfigurator from "./ProductConfigurator";

class ProductList extends React.Component {
    state = {
        products: [],
    }

    componentDidMount() {
        Api.GetProducts().then((products) => {
            this.setState({
                products: products.map(p => {
                    return {
                        ...p,
                    }
                }),
            });
        });
    }

    render() {
        console.log(this.state.products);
        return <div className="productlist">
            {this.state.products.map((p, pi) => {
                const classNames = ['product'];
                if (pi % 2 == 0) {
                    classNames.push("even");
                } else {
                    classNames.push("odd");
                }

                return <a href={p.detailPage} className={classNames.join(" ")}  key={pi}>
                    <div className="picture"><img src={p.image}/></div>
                    <div className="content">
                        <h3 className="name">{p.name}</h3>
                        <p className="description">{p.shortDescription}</p>
                        <ProductConfigurator product={p} />
                    </div>
                    <hr className="content-separator-mobile-light"/>
                </a>
            })}
        </div>
    }
}

export default connect()(ProductList);