import Api from "../../RealApi";
import {v4 as uuidv4} from "uuid";

export function checkout() {
    return {
        type: "CHECKOUT",
        payload: {}
    }
}

export function show() {
    document.querySelector("body").classList.add("prevent-scroll");
    return {
        type: "SHOW",
        payload: {}
    }
}

export function hide() {
    document.querySelector("body").classList.remove("prevent-scroll");
    return {
        type: "HIDE",
        payload: {}
    }
}

export function loadShoppingCart() {
    return dispatch => {

        Api.GetShoppingCartProducts()
            .then(r => {
                return r.text();
            }).then(r => {
            console.warn("CART");

            var element = document.createElement('div');
            element.insertAdjacentHTML('beforeend', r);


            const cart = element.querySelector(".woocommerce-cart-form__contents");

            let products = [];
            if (cart) {
                products = [...cart.querySelectorAll('.woocommerce-cart-form__cart-item')].map(item => {

                    console.dir(item);
                    const priceContainer = item.querySelector(".product-price .woocommerce-Price-amount bdi");
                    const currencySymbol = priceContainer.querySelector(" .woocommerce-Price-currencySymbol");
                    currencySymbol.remove();

                    return {
                        image: item.querySelector(".product-thumbnail img").src,
                        title: item.querySelector(".product-name a").innerHTML,
                        packageSize: item.querySelector(".product-name .variation dd.variation-Verpackungsgre p").innerHTML,
                        displayPrice: item.querySelector(".product-name .variation dd.variation-Anzeigepreis p").innerHTML,
                        price: parseFloat(priceContainer.innerHTML.replace(',', '.')),
                        amount: parseInt(item.querySelector('.product-quantity input').value),
                        removeLink: item.querySelector('.product-remove a').href,
                        cartHash: item.querySelector('.product-quantity input').name.replace(/cart\[([\w]+)\]\[qty\]/g, "$1"),
                    };
                });
            }

            dispatch({
                type: "LOAD_ITEMS_SUCCESS",
                payload: {
                    products
                }
            });
        });
    }
}

export function removeItem(removeLink) {
    return dispatch => {
        dispatch({
            type: "REMOVE_ITEM_REQUEST",
            payload: {removeLink}
        });
        fetch(removeLink)
            .then(r => {
                dispatch({
                    type: "REMOVE_ITEM_SUCCESS",
                    payload: {removeLink}
                });
                dispatch(addNotification("Artikel wurde aus dem Warenkorb entfernt."));
            })
            .catch(e => {
                dispatch({
                    type: "REMOVE_ITEM_FAILURE",
                    payload: e
                });
            })
    };
}

export function addNotification(text) {
    return dispatch => {
        const id = uuidv4();
        dispatch({
            type: "ADD_NOTIFICATION",
            payload: {
                text: text,
                id: id
            }
        });

        setTimeout(() => {
            dispatch({
                type: "CLEAR_NOTIFICATION",
                payload: {
                    id: id
                }
            });
        }, 5000);
    }
}

export function addItem(variationId, quantity) {
    // public function add_to_cart( $product_id = 0, $quantity = 1, $variation_id = 0, $variation = array(), $cart_item_data = array() ) {
    return dispatch => {
        dispatch({
            type: "ADD_ITEM_REQUEST",
        });
        fetch('/?wc-ajax=add_to_cart', {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            body: "product_id=" + variationId + "&quantity=" + quantity,
        })
            .then(r => r.json())
            .then(data => {
                dispatch({
                    type: "ADD_ITEM_SUCCESS",
                });
                dispatch(loadShoppingCart());
                dispatch(addNotification("Artikel wurde dem Warenkorb hinzugefügt."));
            })
            .catch(err => {
                dispatch({
                    type: "ADD_ITEM_FAILURE",
                });
            });
    }
}

export function setAmountForCartItem(cart_item_key, qty) {
    return dispatch => {
        dispatch({
            type: 'SET_AMOUNT_REQUEST'
        });
        // types: ['INCREASE_AMOUNT_REQUEST', 'INCREASE_AMOUNT_SUCCESS', 'INCREASE_AMOUNT_FAILURE'],
        fetch(wc_add_to_cart_params.ajax_url + "?action=qty_cart", {
            // fetch('/?wc-ajax=add_to_cart', {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            body: "hash=" + cart_item_key + "&quantity=" + qty,
        }).then(r => {
            dispatch({
                type: 'SET_AMOUNT_REQUEST_SUCCESS'
            });
            dispatch(loadShoppingCart());
        }).catch(e => {
            dispatch({
                type: 'SET_AMOUNT_REQUEST_FAILURE',
                error: e
            });
        });
    }
}

//
// export function decreaseAmount(index) {
//     return {
//         types: ['DECREASE_AMOUNT_REQUEST', 'DECREASE_AMOUNT_SUCCESS', 'DECREASE_AMOUNT_FAILURE'],
//         shouldCallAPI: () => true,
//         callAPI: () => fetch(`http://localhost/api/v1/shoppingcart/items/${index}/decreaseAmount`),
//         payload: {index}
//     }
// }