import {connect} from "react-redux";
import update from "immutability-helper";
import {addItem} from "./shoppingcart/shoppingcart.actionCreators";
import React from "react";
import {formatEuroMoney} from "../moneyformat";
import {quickAddbarSetProduct} from "./quickaddbar/quickaddbar.actionCreators";

class ProductConfigurator extends React.Component {
    static props = {
        updateProductDetailState: false
    }

    state = {
        selectedPackageSizeIndex: 0,
        selectedBottleSizeIndex: 0,
    }

    componentDidMount() {
        this.updateQuickAddBar();
    }

    updateQuickAddBar() {
        const p = this.props.config;
        // this.bottleSizes = [...new Set(p.map(a => a.attributes).map(a => a.attribute_flaschengroesse))];
        this.packageSizes = [...new Set(p.map(a => a.attributes).map(a => a.attribute_verpackungsgroesse))];

        const selectedPackageSize = this.packageSizes[this.state.selectedPackageSizeIndex];
        const selectedProduct = p.find(p => p.attributes.attribute_verpackungsgroesse === selectedPackageSize);
        this.props.dispatch(quickAddbarSetProduct({
            // bottleSize: selectedBottleSize,
            packageSize: selectedPackageSize,
            name: this.props.name,
            price: selectedProduct.display_price,
            variationId: selectedProduct.variation_id
        }));
    }

    render() {
        const p = this.props.config;

        if (!p) return "";
        // const allAttributes = p.map(a => a.attributes).reduce((arr, item) => arr.concat([item]), []);
        const allAttributes = p.map(a => Object.keys(a.attributes))
        // .reduce((arr, item) => arr.concat(item), []);

        const attr_anzahl_flaschen = "attribute_verpackungsgroesse";
        const attr_anzeigepreis = "attribute_anzeigepreis";

        // const bottleSizes = [...new Set(p.map(a => a.attributes).map(a => a.attribute_flaschengroesse))];
        const packageSizes = [...new Set(p.map(a => a.attributes).map(a => a[attr_anzahl_flaschen]))];
        const anzeigePreis = p.map(a => a.attributes).map(a => a[attr_anzeigepreis]);

        // const selectedBottleSize = bottleSizes[this.state.selectedBottleSizeIndex];
        const selectedPackageSize = packageSizes[this.state.selectedPackageSizeIndex];
        const selectedAnzeigePreis = anzeigePreis[this.state.selectedPackageSizeIndex];
        const selectedProduct = p.find(p => p.attributes[attr_anzahl_flaschen] === selectedPackageSize);

        if(!this.props.is_purchasable) {
            return <div className={"stock out-of-stock"}>Ausgetrunken</div>;
        }
        return <div className={"productConfigurator"}>
            <h3 className={"displayPrice"}>{selectedAnzeigePreis}€</h3>
            <span className={"deliveryNotice"}>Lieferbar ab 6 Flaschen</span>
            <span className={"mixedBoxesLink"}>Auch als <a href="/shop/#gemischte-boxen">gemischte Box</a> erhältlich.</span>
            <select className={"mt-5"} onChange={(e) => {
                e.preventDefault();

                this.setState(update(this.state, {
                    selectedPackageSizeIndex: {$set: e.target.value}
                    // selectedPackageSizeIndex: {$set: 0}
                }), () => {
                    this.updateQuickAddBar();
                });
            }}>
                {packageSizes
                    .map((bottleSize, i) => {
                        {/*        const isAvailable = !!p.find(p => p.attributes.attribute_flaschengroesse === selectedBottleSize && p.attributes.attribute_verpackungsgroesse === packageSize);*/
                        }

                        const isAvailable = !!p.find(p => p.attributes[attr_anzahl_flaschen] === selectedPackageSize);


                        return <option key={i} value={i} disabled={!isAvailable}>{bottleSize}</option>
                    })}
            </select>
            <br/>
            {!selectedProduct.is_purchasable && <div>Derzeit leider nicht verfügbar</div>}
            {selectedProduct.is_purchasable && <button className="add-to-shoppingcart"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           this.props.dispatch(addItem(selectedProduct.variation_id, 1));
                                                       }}
            >
                {/*<span className="price">{selectedProduct && formatEuroMoney(selectedProduct.display_price)}&euro;</span>*/}
                <span className="text">In den Warenkorb</span>
            </button>}
        </div>
    }
}

export default connect()(ProductConfigurator);